/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

import React from "react"
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
// import { Layout } from "./src/components/layout"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <>{element}</>
  // return <Layout {...props}>{element}</Layout>
}

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  Sentry.init({
    dsn: "https://095cfde224ca4f3482421c90a17b9ea9@sentry.argo22.com/32",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.0001,
    allowUrls: [/triviaboss\.com/i],
    ignoreErrors: [
      "ProperMedia is not defined",
      "'ProperMedia' is undefined",
      "Can't find variable: ProperMedia",
      "Unexpected token '<'",
      "Unable to get property 'round' of undefined or null reference",
      "Unable to get property 'sqrt' of undefined or null reference",
      "Object expected",
      "Object doesn't support this action",
      "ResizeObserver loop limit exceeded",
      "Failed to fetch",
      "Unexpected EOF",
      "Illegal invocation",
      "e is undefined",
      "Unexpected end of input",
      "No error message",
      "Unexpected token 'else'",
      "m is null",
      "undefined",
      "null is not an object (evaluating 'e.match(/id\\d+/g)[0]')",
      "null is not an object (evaluating 't.contentDocument.body')",
      "ResizeObserver loop completed with undelivered notifications.",
      "out of memory",
      "Invalid or unexpected token",
      "Unexpected end of script",
      "illegal character U+009E",
      "missing ) after argument list",
      "Unexpected number",
      "Failed to execute 'removeChild' on 'Node': parameter 1 is not of type 'Node'.",
      "null is not an object (evaluating 'c.parent')",
      "Can't find variable: logMutedMessage",
      "Cannot read properties of null (reading 'split')",
      "NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https://ct.pinterest.com/stats/'.",
      "this._resizeCallback is not a function",
      "null is not an object (evaluating 'this.getContainer().ownerDocument')",
      "Can't access contentWindow of an iframe, skip placement initialization.",
      "Missing resources for /",
      "null is not an object (evaluating 'a.contentDocument.body')",
      "tlAdhesion is not defined",
      "SyntaxError: Failed to execute 'measure' on 'Performance': The mark '[object Object]' does not exist.",
      "null is not an object (evaluating 'this.placement.getContainer().getBoundingClientRect')",
      "SecurityError: Blocked a frame with origin \"https://triviaboss.com\" from accessing a cross-origin frame. Protocols, domains, and ports must match.",
      "unexpected token: identifier",
      "Network request failed",
      "Cannot read properties of null (reading 'appendChild')",
      "null is not an object (evaluating 'this.frame.contentDocument.querySelector(\"head\").appendChild')",
      "null has no properties",
      "null is not an object (evaluating 'railSlot.appendChild')",
    ],
    beforeSend(event, hint) {
      // Modify the event here
      if (event['exception']['values']
        && 'stacktrace' in event['exception']['values'][0]
        && 'frames' in event['exception']['values'][0]['stacktrace']
      ) {
        let framesLength = event['exception']['values'][0]['stacktrace']['frames'].length
        if (event['exception']['values'][0]['stacktrace']['frames'][framesLength-1]['filename'].includes('triviaboss.com')) {
          return event
        }
      }
    },
  });
}

export const onInitialClientRender = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: "gatsby-initial-client-render" })
}
