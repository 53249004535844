// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-quiz-list-tsx": () => import("/code/src/templates/QuizList.tsx" /* webpackChunkName: "component---src-templates-quiz-list-tsx" */),
  "component---src-templates-quiz-intro-tsx": () => import("/code/src/templates/QuizIntro.tsx" /* webpackChunkName: "component---src-templates-quiz-intro-tsx" */),
  "component---src-templates-quiz-results-tsx": () => import("/code/src/templates/QuizResults.tsx" /* webpackChunkName: "component---src-templates-quiz-results-tsx" */),
  "component---src-templates-quiz-tsx": () => import("/code/src/templates/Quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-pages-404-tsx": () => import("/code/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("/code/src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("/code/src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dmca-tsx": () => import("/code/src/pages/dmca.tsx" /* webpackChunkName: "component---src-pages-dmca-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/code/src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sponsored-tsx": () => import("/code/src/pages/sponsored.tsx" /* webpackChunkName: "component---src-pages-sponsored-tsx" */),
  "component---src-pages-sweepsrules-tsx": () => import("/code/src/pages/sweepsrules.tsx" /* webpackChunkName: "component---src-pages-sweepsrules-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("/code/src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/code/.cache/data.json")

