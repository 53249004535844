module.exports = [{
      plugin: require('/code/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MJDWZ8B","includeInDevelopment":false},
    },{
      plugin: require('/code/node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-52089501-20","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Performance","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('/code/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
